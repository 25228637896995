import React, {memo, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ManageBookings from "../components/screens/bookings/ManageBookings";
import {fetchUser} from "../general/helper";
import Loading from "../components/common/Loading";

const BookingsPage = memo(({userInfo, setUserInfo, accommodationId}) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const userUpdated = location?.state?.userUpdated;
	
	useEffect(() => {
		if (userUpdated) {
			setLoading(true);
			fetchUser()
				.then(userData => {
					if (!userData || userData.loggedIn === false) {
						// If userData has an error or not logged in, navigate to the SPA page
						toSpaPage();
					} else {
						// Update user info only if userData is valid and loggedIn is true
						setUserInfo({...userData});
					}
				})
				.catch(error => {
					console.error('Error fetching user data:', error);
					// If there's an error fetching user data, navigate to the SPA page
					toSpaPage();
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [userUpdated, setUserInfo, navigate, location.search]); // Added dependencies

	
	const toSpaPage = () => {
		const currentUrlParams = new URLSearchParams(location.search);
		const newUrl = `/spa?${currentUrlParams.toString()}`;
		navigate(newUrl);
	};
	
	
	if (loading) {
		return <Loading/>;
	}
	
	return (
		<div className="container pb-12 mb-4">
			<ManageBookings user={userInfo?.user} goToHome={toSpaPage}/>
		</div>
	);
});

export default BookingsPage;
