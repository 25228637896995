import React, { memo, useState } from "react";
import {
  convertToLocaleDateString,
  deleteAppointment,
  getToken,
  isDateTimeBeforeNow,
  removeAccents,
} from "../../../general/helper";
import { FaLongArrowAltRight } from "react-icons/fa";
import Alert from "../../common/Alert";
import ErrorAlert from "../../common/ErrorAlert";
import { useTranslation } from "react-i18next";

const Booking = memo(({ appointment, fetchAppointments }) => {
  const { id, pickUp, dropOff, start } = appointment;
  const { t } = useTranslation();

  const [clickDelete, setClickDelete] = useState(false);
  const [error, setError] = useState(false);

  const deleteAppointmentHandler = async (token, id) => {
    setError(false);
    const deleted = await deleteAppointment(token, id);
    if (deleted.id) {
      fetchAppointments();
    } else {
      setError(true);
    }
    setClickDelete(false);
  };

  return (
    <>
      <div className="border-t border-b border-sienna-brown my-6">
        <div className="pt-6 pb-4">
          <div className="flex justify-between">
            <div className="my-label uppercase text-charcoal tracking-wider text-base">
              {convertToLocaleDateString(start.split(" ")[0])} at{" "}
              {start.split(" ")[1].substring(0, 5)}
            </div>
            {isDateTimeBeforeNow(start) && (
              <div className="my-label uppercase text-charcoal tracking-wider text-base">
                {removeAccents(t("passed"))}
              </div>
            )}
          </div>
          <div className="text-sm text-charcoal flex items-center my-text w-3/4 my-2">
            {/*<div className="mr-2">{pickUp}</div>*/}
            {/*<FaLongArrowAltRight className="mx-4 text-sienna-brown" size={20} />*/}
            {/*<div className="ml-2">{dropOff}</div>*/}
            <div>{appointment?.service?.name}</div>
          </div>
          {!isDateTimeBeforeNow(start) && (
            <button
              className="text-charcoal mt-4 w-fit hover:cursor-pointer uppercase"
              onClick={() => setClickDelete(!clickDelete)}
            >
              <div>{removeAccents(t("delete"))} ></div>
            </button>
          )}
        </div>
      </div>
      {clickDelete && (
        <Alert
          isOpen={clickDelete}
          message={`${t("areYouSureWantDelete")}`}
          onClose={() => setClickDelete(false)}
          onAprove={() => deleteAppointmentHandler(getToken(), id)}
        />
      )}
      {error && (
        <ErrorAlert
          isOpen={error}
          message={`${t("failedDeleteAppointment")}`}
          onClose={() => setError(false)}
        />
      )}
    </>
  );
});

export default Booking;
