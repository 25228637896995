import React, {useState, useEffect} from "react";
import {memo} from "react";
import "../../../styles/phone-input.css";
import Pin from "./Pin";
import Phone from "./Phone";
import validator from "validator";
import {
	getQueryParam,
	sendingCode,
	setLocalStorageWithTimer,
	verifyCode,
} from "../../../general/helper";
import Loading from "../../common/Loading";

const TwoFA = memo(
	({accommodationId, goToRedirect, goToHome}) => {
		const [geoInfo, setGeoInfo] = useState();
		const [phone, setPhone] = useState("");
		const [sendCode, setSendCode] = useState(false);
		const [loading, setLoading] = useState(false);
		const [error, setError] = useState(false);
		const getGeoInfo = () => {
			fetch("https://ipapi.co/json/")
				.then((response) => response.json()) // Parse the response as JSON
				.then((data) => {
					setGeoInfo(data);
				})
				.catch((error) => {
					console.log(error);
				});
		};
		
		useEffect(() => {
			getGeoInfo();
		}, []);
		
		const sendCodeHandler = async (phone) => {
			const isValid = validator.isMobilePhone(`+${phone}`);
			if (isValid) {
				setError(false);
				setLoading(true);
				const isSent = await sendingCode(phone, accommodationId);
				setLoading(false);
				if (isSent === true) {
					setSendCode(true);
				}
			} else {
				setError(true);
			}
		};
		
		const verifyPinHandler = async (pin) => {
			const verifyPayload = JSON.stringify({
				code: `${pin}`,
				mobileNumber: phone,
				accommodationId,
			});
			
			setLoading(true);
			const isVerify = await verifyCode(accommodationId, verifyPayload);
			if (isVerify === true) {
				setError(false);
				setLocalStorageWithTimer(
					`verifiedMobile-reserve-${getQueryParam("lid")}`,
					phone,
					900000
				);
				
				if (goToRedirect) {
					goToRedirect();
				}
			} else {
				setLoading(false);
				setError(true);
			}
		};
		
		return (
			<>
				{loading ? (
					<Loading/>
				) : (
					<div>
						{!sendCode ? (
							<Phone
								error={error}
								phone={phone}
								setPhone={setPhone}
								geoInfo={geoInfo}
								sendCodeHandler={sendCodeHandler}
								goToHome={goToHome ? () => goToHome() : undefined}
							/>
						) : (
							<Pin
								error={error}
								verifyPinHandler={verifyPinHandler}
								sendCodeHandler={() => sendCodeHandler(phone)}
							/>
						)}
					</div>
				)}
			</>
		);
	}
);

export default TwoFA;
