import React, {memo, useCallback} from "react";
import CustomButton from "../../common/CustomButton";
import PhoneInput, {formatPhoneNumberIntl} from "react-phone-input-2";
import {useTranslation} from "react-i18next";
import {removeAccents} from "../../../general/helper";

const Phone = memo(({error, phone, setPhone: setPhoneProp, geoInfo, sendCodeHandler, goToHome}) => {
	const {t} = useTranslation();
	
	const setPhone = useCallback((value, countryData) => {
		let formattedPhone = value;
		// Check if the phone number includes the country code
		if (value && countryData && value.startsWith(countryData.dialCode) === false) {
			// Prepend the country code if it's missing
			formattedPhone = `${countryData.dialCode}${value}`;
		}
		setPhoneProp(formattedPhone);
	}, [setPhoneProp]);
	
	return (
		<div>
			<div className="my-text">
				<h1 className="text-sd text-charcoal mt-4 mb-2">
					{t("enterMobileNumber")}
				</h1>
				<p className="text-sm mt-2 mb-4">{t("willSendYouCode")}</p>
			</div>
			<div onClick={(e) => e.preventDefault()}>
				<PhoneInput
					value={phone}
					onChange={setPhone}
					inputProps={{
						name: "phone",
						required: true,
						autoFocus: true,
					}}
					specialLabel={false}
					country={
						geoInfo?.country_code ? geoInfo.country_code.toLowerCase() : "gr"
					}
					jumpCursorToEnd={false}
				/>
				{error && (
					<p className="text-center my-text text-sm text-red-500">
						{t("invalidMobile")}
					</p>
				)}
				<CustomButton
					fullWidth={true}
					onClick={() => sendCodeHandler(phone)}
					type={"button"}
					text={`${removeAccents(t("sendCode"))} >`}
				/>
				{goToHome && (<CustomButton
					fullWidth={true}
					text={`${removeAccents(t("backToHome"))} >`}
					onClick={goToHome}
				/>)}
			</div>
		</div>
	);
});

export default Phone;
