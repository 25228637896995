import React, {useEffect, useState, lazy, Suspense} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Loading from "./components/common/Loading";
import Header from "./components/common/Header";
import Error from "./components/common/Error";
import {fetchProperties, getQueryParam, validateAccommodationId, fetchUser, isGuestAtSameLid} from "./general/helper";
import TwoFAPage from "./pages/TwoFAPage";
import BookingsPage from "./pages/BookingsPage";

const OutletPage = lazy(() => import("./pages/OutletPage"));
const SpaPage = lazy(() => import("./pages/SpaPage"));
const SpaPackagePage = lazy(() => import("./pages/SpaPackagePage"));
const SpaTherapyPage = lazy(() => import("./pages/SpaTherapyPage"));
const SpaSlotsPage = lazy(() => import("./pages/SpaSlotsPage"));
const SummaryPage = lazy(() => import("./pages/SummaryPage"));
const ConfirmationPage = lazy(() => import("./pages/ConfirmationPage"));

export default function App() {
	const lid = getQueryParam('lid');
	const [loading, setLoading] = useState(false);
	const [accommodationId, setAccommodationId] = useState(lid);
	const [languages, setLanguages] = useState([]);
	const [userInfo, setUserInfo] = useState(null);
	
	useEffect(() => {
		const fetchData = async () => {
			if (!validateAccommodationId(lid)) {
				return;
			}
			setAccommodationId(lid);
			try {
				setLoading(true);
				const properties = await fetchProperties(lid);
				const parsedLanguages = properties[0]?.values?.mobileapp?.guestLanguageOptions?.split(",")?.map(lang => lang.trim());
				setLanguages(parsedLanguages);
				
				const userData = await fetchUser();
				setUserInfo({...userData});
			} catch (error) {
			} finally {
				setLoading(false);
			}
		};
		
		fetchData();
	}, [lid]);
	
	if (!validateAccommodationId(lid)) {
		return <Error/>;
	}
	
	return (
		<Suspense fallback={<Loading/>}>
			<Router>
				{/*<Header languages={languages}/>*/}
				{loading ? (
					<Loading/>
				) : (
					<Routes>
						<Route exact path="/:outletId"
						       element={<OutletPage accommodationId={accommodationId} userInfo={userInfo}
						                            setUserInfo={setUserInfo}
						                            languages={languages}/>}/>
						{/* Spa's pages */}
						<Route exact path="/spa"
						       element={<SpaPage userInfo={userInfo} accommodationId={accommodationId} languages={languages}/>}/>
						<Route exact path="/spa/:packageId"
						       element={<SpaPackagePage userInfo={userInfo} accommodationId={accommodationId}/>}/>
						<Route exact path="/spa/:packageId/:therapyId"
						       element={<SpaTherapyPage userInfo={userInfo} accommodationId={accommodationId}/>}/>
						<Route exact path="/spa/:packageId/:therapyId/slots"
						       element={<SpaSlotsPage userInfo={userInfo} accommodationId={accommodationId}/>}/>
						<Route exact path="/summary"
						       element={<SummaryPage userInfo={userInfo}
						                             setUserInfo={setUserInfo}
						                             accommodationId={accommodationId}/>}/>
						<Route exact path="/confirmation"
						       element={<ConfirmationPage userInfo={userInfo} accommodationId={accommodationId}/>}/>
						<Route exact path="/2fa"
						       element={<TwoFAPage userInfo={userInfo} accommodationId={accommodationId}/>}/>
						<Route exact path="/bookings"
						       element={<BookingsPage userInfo={userInfo} setUserInfo={setUserInfo}
						                              accommodationId={accommodationId}/>}/>
						{/* Add other routes as needed */}
					</Routes>
				)}
			</Router>
		</Suspense>
	);
}
