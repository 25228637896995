import React, { useState, useEffect } from "react";

const ErrorAlert = ({ message, isOpen, onClose, timeout }) => {
  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    let timerId;
    if (isOpen) {
      setVisible(true);
      if (timeout) {
        timerId = setTimeout(() => {
          setVisible(false);
          onClose();
        }, timeout);
      }
    } else {
      setVisible(false);
      clearTimeout(timerId);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [isOpen, onClose, timeout]);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 transition-opacity duration-300 ${
        visible ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50"
        onClick={onClose ? handleClose : null}
      ></div>
      <div className="bg-[#FBD5D5] w-1/2 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-aut text-red-500">
        <div className="py-4 px-6">
          {/* <div className="flex justify-between items-center mb-4">
              <h2 className="my-label text-lg font-bold uppercase">Error</h2>
            </div> */}
          <p className="my-text text-gray-700 text-base">{message}</p>
          <div className="mt-2 flex justify-end">
            {onClose && (
              <button
                className="uppercase my-text px-3 py-1 text-xs sm:px-4 sm:py-2 sm:text-sm text-white bg-red-500 rounded-md hover:bg-red-600 focus:bg-red-600 focus:outline-none"
                onClick={onClose ? handleClose : null}
              >
                ok
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorAlert;
