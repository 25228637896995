import axios from "axios";
import moment from "moment";

export const BASE_URL = "https://api.okgini.com/api";

export const codeLanguageToString = {
	en: 'English', // English
	de: 'Deutsch', // Deutsch (German)
	fr: 'French', // French
	it: 'Italian', // Italian
	ru: 'Russian', // Russian
	el: 'Greek', // Greek
	uk: 'Ukrainian', // Ukrainian
	es: 'Spanish', // Spanish
	du: 'Dutch', // Dutch
	cs: 'Czech', // Czech
	pl: 'Polish', // Polish
	he: 'Hebrew', // Hebrew
	ar: 'Arabic',  // Arabic
	et: 'Estonian', // Estonian
	lt: 'Lithuanian', // Lithuanian
	tr: 'Turkish', // Turkish
	nl: 'Dutch', // Dutch (again, as 'du' is not standard)
};

// Actions helper
export const getHelper = async (url, headers) => {
	try {
		const response = await axios.get(url, {headers});
		return response.data;
	} catch (error) {
		return error;
	}
};

export const postHelper = async (url, headers, body) => {
	try {
		const response = await axios.post(url, body, {headers});
		return response.data;
	} catch (error) {
		return error;
	}
};


export const putHelper = async (url, headers, body) => {
	try {
		const response = await axios.put(url, body, {headers});
		return response.data;
	} catch (error) {
		return error;
	}
};
export const deleteHelper = async (url, headers) => {
	try {
		const response = await axios.delete(url, {headers});
		return response.data;
	} catch (error) {
		return error;
	}
};

// Requests
export const fetchDinings = (accommodationId) =>
	getHelper(`${BASE_URL}/dinings`, {
		authorization: `lid ${accommodationId}`,
	});

export const fetchProperties = (accommodationId) =>
	getHelper(`${BASE_URL}/properties?category=general`, {
		authorization: `lid ${accommodationId}`,
	});

export const sendingCode = (phone, accommodationId) =>
	getHelper(
		`${BASE_URL}/routee/sendCodeToCustomer/mobileNumber/${phone}/accommodationId/${accommodationId}`,
		{
			authorization: `token ${accommodationId}-en`,
		}
	);

export const verifyCode = (accommodationId, body) =>
	postHelper(
		`${BASE_URL}/routee/verifyCode`,
		{
			authorization: `token ${accommodationId}-en`,
			"content-type": "application/json",
		},
		body
	);

export const createAccount = (accommodationId, body) =>
	postHelper(
		`${BASE_URL}/loyaltyClub/acm/${accommodationId}`,
		{
			"content-type": "application/json",
			"content-length": `${body.length}`,
		},
		body
	);

export const updateAccount = (accommodationId, body, id) =>
	putHelper(
		`${BASE_URL}/loyaltyClub/acm/${accommodationId}/${id}`,
		{
			"content-type": "application/json",
		},
		body
	);

export const makeAppointment = (body, token, hasExtraDetails, doNotShowDateTime, serviceId) =>
	postHelper(
		hasExtraDetails ? `${BASE_URL}/eaWithExtraDetails/guest/appointments` : (doNotShowDateTime && serviceId) ? `${BASE_URL}/dinings/${serviceId}` : `${BASE_URL}/ea/guest/appointments`,
		{
			"content-type": "application/json",
			authorization: `token ${token}`,
		},
		body
	);

export const completeSpaReservation = (body, token, spaPackageId) =>
	postHelper(
		 `${BASE_URL}/spa/${spaPackageId}`,
		{
			"content-type": "application/json",
			authorization: `token ${token}`,
		},
		body
	);

export const isGuestEntitled = (providerId, reservationNumber, startDate, accommodationId) => {
	if (!providerId || !reservationNumber) {
		return {isEntitled: true};
	}
	return getHelper(
		`${BASE_URL}/ea/providers/${providerId}/entitled/${reservationNumber}?startDate=${startDate}`,
		{
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		}
	);
};


export const getUserByType = (type) =>
	getHelper(`${BASE_URL}/launcher/info`, {
		authorization: `token ${type}`,
	});

export const getUserByReservationNumber = (reservationNumber, accommodationId) =>
	getHelper(`${BASE_URL}/guests/findByReservationNumber/${reservationNumber}`, {
		authorization: `token ${accommodationId}-en`,
	});

export const getLoyaltyMemberWithToken = (token, accommodationId) =>
	getHelper(`${BASE_URL}/routee/getLoyaltyMember/token/${token}`, {
		authorization: `token ${accommodationId}-en`,
	});

export const checkIfTokenHasExpired = (token, accommodationId) =>
	getHelper(`${BASE_URL}/routee/checkIfTokenHasExpired/token/${token}`, {
		authorization: `token ${accommodationId}-en`,
	});

export const checkUserIfExistAndGetToken = (phone, accommodationId) =>
	getHelper(
		`${BASE_URL}/routee/checkIfExistsAndLogIn/mobileNumber/${phone}/accommodationId/${accommodationId}`,
		{authorization: `token ${accommodationId}-en`}
	);

export const getAppointments = (token) =>
	getHelper(`${BASE_URL}/ea/guest/appointments`, {
		authorization: `token ${token}`,
	});

export const deleteAppointment = (token, id) =>
	deleteHelper(`${BASE_URL}/ea/guest/appointments/${id}`, {
		authorization: `token ${token}`,
	});

export const fetchUser = async () => {
	const lid = getQueryParam("lid");
	const tokenKey = `token-${lid}`;
	const verifiedMobileKey = `verifiedMobile-reserve-${lid}`;
	const type = getQueryParam("type");
	const token = localStorage.getItem(tokenKey);
	const verifiedMobile = getLocalStorageWithTimer(verifiedMobileKey);
	const accommodationId = lid;
	const reservationNumber = getQueryParam("res")
	
	// Logged in by type (guest) or by res (reservationNumber)
	if ((type || reservationNumber) && lid) {
		try {
			let user = {}
			let userAccommodationId = undefined
			if (type) {
				user = await getUserByType(type)
				userAccommodationId = user?.accommodation?.id
			} else {
				user = await getUserByReservationNumber(reservationNumber, accommodationId)
				// Save guest token on localStorage if fetched user by reservation number
				localStorage.setItem(tokenKey, user.token)
				userAccommodationId = user.accommodationId
			}
			if (isGuestAtSameLid(userAccommodationId, lid)) {
				const userTypeObject = type ? {...user.user, userType: "guest"} : {
					...user,
					lastName: user.name,
					email: {email: user?.email},
					userType: "guest"
				};
				return {user: userTypeObject, loggedIn: true};
			} else {
				return {error: "Guest not found. Please verify your guest details.", loggedIn: false};
			}
		} catch (error) {
			return {userType: "guest", error: "Error fetching guest", loggedIn: false};
		}
	}
	
	// Logged In by Token (2fa - loyalty)
	if ((!type || !reservationNumber) && lid && (verifiedMobile || token)) {
		try {
			const isTokenExist = verifiedMobile ? await checkUserIfExistAndGetToken(verifiedMobile, accommodationId) : undefined;
			if (isTokenExist === undefined || isTokenExist === null) {
				const tokenIsExpired = token ? await checkIfTokenHasExpired(token, accommodationId) : undefined
				if (tokenIsExpired === undefined || tokenIsExpired === true || tokenIsExpired === null || tokenIsExpired.length === 0) {
					return {error: "Token expired", loggedIn: false};
				}
			}
			const userToken = isTokenExist ? isTokenExist : token;
			const userLoyaltyObject = userToken ? await getLoyaltyMemberWithToken(userToken, accommodationId) : null;
			if (userLoyaltyObject && isGuestAtSameLid(userLoyaltyObject.accommodationId, lid)) {
				const user = {...userLoyaltyObject, userType: "loyalty"};
				localStorage.setItem(tokenKey, userToken);
				return {user, loggedIn: true};
			} else {
				return {loggedIn: false};
			}
			return {userType: "loyalty", error: "Error fetching loyalty user", loggedIn: false};
		} catch (error) {
			return {userType: "loyalty", error: "Error fetching loyalty user", loggedIn: false};
		}
	}
	return {error: "Invalid parameters", loggedIn: false};
};


export const getAccommodationInfo = (lid) => getHelper(`${BASE_URL}/accommodation/info?app=true`, {
	authorization: `token ${lid}-en`,
});

export const getWebMenu = (lid, activity) =>
	getHelper(`${BASE_URL}/menu/getWebMenus?outletId=${activity}&from=${getCurrentDate()}&to=${getCurrentDate()}`, {
		authorization: `lid ${lid}`,
	});

export const getSpaMenu = (lid, lang = 'en') => getHelper(`${BASE_URL}/spa/class/spa/lang/${lang}`, {
	authorization: `lid ${lid}`,
});

export const getSlots = (lid, serviceId, date) => getHelper(`${BASE_URL}/ea/guest/slots?serviceId=${serviceId}&date=${date}`, {
	authorization: `lid ${lid}`,
});

export const getWebServiceFromEAServiceId = (lid, serviceId) => getHelper(`${BASE_URL}/webService/getWebServiceFromEAServiceId/${serviceId}`, {
	authorization: `lid ${lid}`,
});

export const getAvailabilitiesDatesForBooker = (lid, from, to, providerId) => getHelper(`${BASE_URL}/booker/getAvailabilitiesDates?from=${from}&to=${to}&serviceId=${providerId}`, {
	authorization: `lid ${lid}`,
});

// Functions

export const calculateTotalPrice = (state) => {
	// Early return if no service is selected to avoid unnecessary calculations
	if (!state.selectedService) {
		return;
	}
	
	let totalPrice = 0;
	
	// Calculate base price depending on whether the service has a fixed price
	if (state.selectedService.hasFixedPrice) {
		const fixedPrice = state.selectedService.prices.find(price => price.type === "Fixed")?.price ?? 0;
		totalPrice += fixedPrice;
	} else {
		// Extract prices for adults and children for readability and maintainability
		const {adults = 0, children = 0} = state.persons; // Destructure with default values
		const adultsPrice = state.selectedService.prices.find(price => price.type === "Adults")?.price ?? 0;
		const childrenPrice = state.selectedService.prices.find(price => price.type === "Children")?.price ?? 0;
		totalPrice += (Number(adults) * adultsPrice) + (Number(children) * childrenPrice);
	}
	
	// Calculate the total price of selected add-ons
	const addOnsPrice = state.selectedAddOns.reduce((sum, addOnName) => {
		const addOnPrice = state.selectedService.addOnGroups.flatMap(group => group.addOns)
			.find(addOn => addOn.name.en === addOnName)?.price ?? 0;
		return sum + addOnPrice;
	}, 0);
	
	totalPrice += addOnsPrice;
	return totalPrice
	
}


export const durationToHoursAndMinutes = (minutes) => {
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;
	
	if (hours > 0 && remainingMinutes > 0) {
		return `${hours}h${String(remainingMinutes).padStart(2, '0')}m`;
	} else if (hours > 0) {
		return `${hours}h`;
	} else {
		return `${remainingMinutes}m`;
	}
}
export const getQueryParam = (param) => {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(param);
};

export const setLocalStorageWithTimer = (key, value, expirationTime) => {
	const item = {
		value,
		expirationTime: Date.now() + expirationTime,
	};
	
	localStorage.setItem(key, JSON.stringify(item));
	
	setTimeout(() => {
		localStorage.removeItem(key);
	}, expirationTime);
};

export const getLocalStorageWithTimer = (key) => {
	const item = localStorage.getItem(key);
	
	if (item) {
		const {value, expirationTime} = JSON.parse(item);
		
		if (expirationTime > Date.now()) {
			return value;
		} else {
			localStorage.removeItem(key);
		}
	}
	
	return null;
};

export const iso = (date) => {
	const pad = (n) => (n < 10 ? "0" + n : n);
	return (
		date.getFullYear() +
		"-" +
		pad(date.getMonth() + 1) +
		"-" +
		pad(date.getDate())
	);
};

export const getTomorrowDate = () => {
	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);
	const yyyy = tomorrow.getFullYear();
	let mm = tomorrow.getMonth() + 1; // getMonth() is zero-based
	let dd = tomorrow.getDate();
	
	// Pad month and day with leading zeros if necessary
	mm = mm < 10 ? '0' + mm : mm;
	dd = dd < 10 ? '0' + dd : dd;
	return `${yyyy}-${mm}-${dd}`;
}



export const datePickerBasedOnTagsForGuest = (tags, guest) => {
	let availableStartDate, availableEndDate;
	if (guest?.userType === "guest") {
		if (tags?.includes("allowReservationsOnlyDuringStayDates")) {
			availableStartDate = new Date(guest.arrivalDate);
			availableEndDate = new Date(guest.departureDate);
		} else if (tags?.includes("allowReservationsOnlyDuringStayDatesExcludingDepartureDate")) {
			availableStartDate = new Date(guest.arrivalDate);
			availableEndDate = new Date(guest.departureDate - (24 * 60 * 60 * 1000));
		} else if (tags?.includes("allowReservationsOnlyForCheckinDate")) {
			availableStartDate = new Date(guest.arrivalDate);
			availableEndDate = availableStartDate;
		}
	}
	return [availableStartDate, availableEndDate]
}

export const categorizeTimeSlots = (slots) => {
	const categorizedSlots = {
		Morning: [],
		Afternoon: [],
		Evening: []
	};
	
	slots.forEach(slot => {
		const hour = parseInt(slot.split(':')[0], 10);
		if (hour < 12) {
			categorizedSlots.Morning.push(slot);
		} else if (hour < 18) {
			categorizedSlots.Afternoon.push(slot);
		} else {
			categorizedSlots.Evening.push(slot);
		}
	});
	
	return categorizedSlots;
};


export const generateBothWaysRoutes = (data) => {
	const routes = [];
	
	for (let i = 0; i < data.length; i++) {
		const source = data[i];
		for (let j = 0; j < data.length; j++) {
			const destination = data[j];
			if (i < j) {
				const forwardCouple = {
					pickUp: source.name,
					dropOff: destination.name,
					providerId: source?.easyAppointments?.providerId,
					serviceId: source?.easyAppointments?.serviceId,
				};
				
				const backwardCouple = {
					pickUp: destination.name,
					dropOff: source.name,
					providerId: destination?.easyAppointments?.providerId,
					serviceId: destination?.easyAppointments?.serviceId,
				};
				routes.push(forwardCouple, backwardCouple);
			}
		}
	}
	return routes.slice(0, 2);
};

export const convertToLocaleDateString = (dateStr) => {
	const months = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"];
	
	// Split the date string into parts
	const parts = dateStr.split('-');
	const year = parts[0];
	const month = months[parseInt(parts[1], 10) - 1]; // Months are zero-indexed in the array
	let day = parseInt(parts[2], 10);
	
	// Determine the ordinal suffix
	let suffix = 'th';
	if (day === 1 || day === 21 || day === 31) suffix = 'st';
	else if (day === 2 || day === 22) suffix = 'nd';
	else if (day === 3 || day === 23) suffix = 'rd';
	
	// Return the formatted date string
	return `${day}${suffix} ${month}, ${year}`;
};

export const getToken = () =>
	getQueryParam("type") ||
	localStorage.getItem(`token-${getQueryParam("lid")}`);

export const isDateTimeBeforeNow = (date) => {
	return new Date(date) < new Date();
};

export const removeAccents = (str) =>
	str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const getCurrentDate = (date = new Date()) => {
	// Ensure `date` is a Date object
	const validDate = date instanceof Date ? date : new Date(date);
	
	// Extract year, day, and month
	const year = validDate.getFullYear();
	const day = validDate.getDate();
	const month = validDate.getMonth() + 1; // Month starts from 0, so we add 1
	
	// Format the date
	const formattedDate = year + '-' + (month < 10 ? '0' + month : month) + "-" + (day < 10 ? '0' + day : day);
	
	// Return the formatted date
	return formattedDate;
};

export const outletId = () => window.location.pathname.split("/")[1] || undefined

export const getInitialDateRange = () => {
	const today = new Date();
	const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
	const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
	
	return {
		from: getCurrentDate(),
		to: getCurrentDate(lastDayOfMonth),
	};
};

export const filterFutureSlots = (slots, isObject = false, selectedDate) => {
	const currentDate = moment().format('YYYY-MM-DD');
	const currentTime = moment();
	
	// If the selected date is not today, return all slots because they are inherently future slots.
	if (currentDate !== selectedDate) {
		return slots;
	}
	
	return slots.filter(slot => {
		const slotTime = isObject ? slot.start : slot; // Determine if slot data includes start time or just time.
		const slotDateTime = moment(`${selectedDate}T${slotTime}`); // Construct the datetime for the slot.
		// Check if the slot time is after the threshold time.
		return slotDateTime.isAfter(currentTime);
	});
};


// Validators
export const isNotEmptyObject = (obj) => {
	return obj ? Object?.keys(obj)?.length !== 0 : false;
};

export const validateAccommodationId = (input) => {
	const regex = /^[a-zA-Z]{3}$/;
	return regex.test(input);
};

export const validateEmail = (email) => {
	// Regular expression pattern for email validation
	const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	
	return emailPattern.test(email);
};

export const validateString = (str) => {
	// Allow letters, digits, spaces, hyphens, and apostrophes
	const namePattern = /^[a-zA-Z0-9 '-]+$/;
	return !!str.trim() && namePattern.test(str.trim());
};

export const isGuestAtSameLid = (guestAcm, lid) => {
	return guestAcm === lid;
};

export const maxAttendantsNumber = (slot, service, persons) => {
	return typeof slot === "object" ? (Number(service.attendantsNumber ?? 0) - Number(slot.attendantsNumber ?? 0) - Number(persons.adults) - Number(persons.children)) : true;
}

export const isEmptyObject = (obj) => {
	return Object.keys(obj).length === 0;
};
