import React from "react";

const Error = () => {
  return (
    <div className="container ">
      <div className="flex items-center justify-center min-h-screen bg-red-100 text-red-900">
        <div className="p-4">
          <h1 className="text-4xl font-bold mb-4">
            Oops! Something went wrong.
          </h1>
          <p className="text-lg mb-4">
            We're sorry, but there seems to have been an error. Please try again
            later with different accommodation.
          </p>
          {/* TODO: REMOVE BUTTON */}
          {/* <button
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              window.location.href = `${window.location.origin}${window.location.pathname}?lid=exp`;
            }}
          >
            Go back
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Error;
