import React, { useState, useEffect } from "react";

const Alert = ({ message, isOpen, onClose, onAprove, timeout }) => {
  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    let timerId;
    if (isOpen) {
      setVisible(true);
      if (timeout) {
        timerId = setTimeout(() => {
          setVisible(false);
          onClose();
        }, timeout);
      }
    } else {
      setVisible(false);
      clearTimeout(timerId);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [isOpen, onClose, timeout]);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 transition-opacity duration-300 ${
        visible ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50"
        onClick={handleClose}
      ></div>
      <div className="bg-alice-white w-1/2 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-aut text-charcoal-500 ">
        <div className="py-4 px-6">
          {/* <div className="flex justify-between items-center mb-4"> */}
          {/* <h2 className="my-label text-lg font-bold uppercase"> Alert Title</h2> */}
          {/* </div> */}
          <p className="my-text text-base text-charcoal">{message}</p>
          <div className="mt-2 flex justify-end">
            <button
              className="uppercase my-text px-3 py-1 text-xs sm:px-4 sm:py-2 sm:text-sm text-white text-charcoal bg-sienna-brown rounded-md hover:text-charcoal focus:outline-none mx-2"
              onClick={handleClose}
            >
              NO
            </button>
            <button
              className="uppercase my-text px-3 py-1 text-xs sm:px-4 sm:py-2 sm:text-sm text-white bg-sienna-brown rounded-md hover:text-charcoal focus:outline-none"
              onClick={onAprove}
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
