import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";

// Import translations
import enTranslations from "./locales/en.json";
import frTranslations from "./locales/fr.json";
import esTranslations from "./locales/es.json";
import elTranslations from "./locales/el.json";
import arTranslations from "./locales/ar.json";
import deTranslations from "./locales/de.json";

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    fr: { translation: frTranslations },
    el: { translation: elTranslations },
    es: { translation: esTranslations },
    ar: { translation: arTranslations },
    de: { translation: deTranslations },
  },
  lng: "en", // Set the default language
  fallbackLng: "en", // Fallback language in case translation is missing
  interpolation: {
    escapeValue: false, // Disable HTML escaping
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
