import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PinInput from "react-pin-input";

const Pin = memo(({ error, verifyPinHandler, sendCodeHandler }) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(11);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <div>
      <div className="my-text">
        <h1 className="text-sd text-charcoal mt-4 mb-2">
          {t("enterVerificationCode")}
        </h1>
        <p className="text-sm mt-2 mb-4">{t("checkYourMobileMessages")}</p>
      </div>
      <div
        onClick={(e) => e.preventDefault()}
        className="flex flex-col justify-center items-center"
      >
        <PinInput
          focus
          length={4}
          initialValue=""
          secret={false}
          type="numeric"
          inputMode="number"
          inputStyle={{
            border: "none",
            borderBottom: "solid 2px #956F56",
            outline: "none",
            margin: 10,
            fontSize: "1.625rem",
            color: "#956F56",
            fontFamily: "Canela, serif",
            backgroundColor: "#F9F4F1",
          }}
          inputFocusStyle={{}}
          onComplete={(value, index) => verifyPinHandler(value)}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />

        <div className="text-sm my-text">
          {error && (
            <p className="text-center text-red-500">{`${t("invalidCode")}`}</p>
          )}
          {timeLeft > 0 ? (
            <span className={`${timeLeft > 0 && "text-slate-300"}`}>
              {`${t("notReceivedTheCode")} - ${timeLeft} sec`}
            </span>
          ) : (
            <span onClick={sendCodeHandler} className="hover:cursor-pointer">
              {`${t("notReceivedTheCode")}`}
            </span>
          )}
          <div
            className={`border border-solid my-1 ${
              timeLeft > 0 ? "border-none" : "border-sienna-brown"
            }`}
          />
        </div>
      </div>
    </div>
  );
});
export default Pin;
