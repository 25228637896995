import React, {memo, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {codeLanguageToString} from "../../general/helper";

const Header = memo(({logo, name, languages}) => {
	const {t, i18n} = useTranslation();
	const [showHeader, setShowHeader] = useState(true);
	
	const handleChangeLanguage = (e) => {
		const newLang = e.target.value;
		i18n.changeLanguage(newLang);
		
		// Update the URL's 'lang' parameter
		const url = new URL(window.location);
		url.searchParams.set('lang', newLang);
		
		// Update the browser URL without reloading the page
		window.history.pushState({}, '', url);
	};
	
	
	useEffect(() => {
		let prevScrollPos = window.pageYOffset;
		
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			setShowHeader(prevScrollPos > currentScrollPos || currentScrollPos === 0);
			prevScrollPos = currentScrollPos;
		};
		
		window.addEventListener("scroll", handleScroll);
		
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	
	// Effect to handle the 'lang' query parameter
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const urlLang = searchParams.get('lang');
		
		// Check if the language from URL is valid and if it exists in the provided languages list
		if (urlLang && languages.includes(urlLang)) {
			i18n.changeLanguage(urlLang);
		} else {
			i18n.changeLanguage('en'); // Default to English
		}
	}, [i18n, languages]);
	
	if (!languages?.length) {
		return null;
	}
	
	return (
		<header
			className={`fixed top-0 right-0 p-4 bg-transparent z-50 my-text text-sm
            ${showHeader ? "opacity-70" : "opacity-0"}
            transition-opacity duration-500`}
			style={{display: 'flex', justifyContent: 'flex-end'}}
		>
			<select value={i18n.language} onChange={handleChangeLanguage}>
				{languages.map((lan, index) => (
					<option key={index} value={lan}>{codeLanguageToString[lan]}</option>
				))}
			</select>
		</header>
	);
});

export default Header;
