import React, { memo } from "react";

const CustomButton = ({ fullWidth, type, text, onClick }) => {
  return (
    <div className="w-full flex justify-center">
      <button
        onClick={onClick}
        type={type}
        className={`${fullWidth ? 'w-full' : 'w-40'} rounded-lg border border-solid border-charcoal px-4 py-4 mx-4 my-4 uppercase hover:bg-sienna-brown hover:text-alice-white hover:border-sienna-brown`}
      >
        {text}
      </button>
    </div>
  );
};

export default CustomButton;
