import React, {memo, useEffect, useState} from 'react';
import "../styles/phone-input.css";
import TwoFA from "../components/screens/2fa/TwoFA";
import {useLocation, useNavigate} from "react-router-dom";

const TwoFAPage = memo(({userInfo, accommodationId}) => {
	
	const location = useLocation();
	const navigate = useNavigate();
	
	
	const {shouldGoToRedirect, redirectPage, stateForRedirectPage} = location.state || {};
	
	useEffect(() => {
		// Check if goToRedirect is defined
		if (!shouldGoToRedirect) {
			const currentUrlParams = new URLSearchParams(location.search).toString();
			navigate(`/spa?${currentUrlParams}`);
		}
	}, [shouldGoToRedirect, location.search, navigate]);
	
	
	return (<div className="container pb-12 my-4">
		<TwoFA
			accommodationId={accommodationId}
			goToRedirect={() => navigate(redirectPage, {state: {...stateForRedirectPage}})}
		/>
	</div>)
});
export default TwoFAPage;