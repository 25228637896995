import React, {memo, useEffect, useState} from "react";
import CustomButton from "../../common/CustomButton";
import {
	getAppointments,
	getToken,
	removeAccents,
} from "../../../general/helper";
import Booking from "./Booking";
import Loading from "../../common/Loading";
import {AiOutlineArrowRight, AiOutlineArrowLeft} from "react-icons/ai";
import {useTranslation} from "react-i18next";

const ManageBookings = memo(({user, goToHome, providers}) => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);
	const [appointments, setAppointments] = useState([]);
	
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5; // Number of appointments to display per page
	const [totalPages, setTotalPages] = useState(0); // Total number of pages
	
	const fetchAppointments = async () => {
		try {
			setLoading(true);
			const appointments = await getAppointments(getToken());
			let enhanceAppointments = [];
			if (appointments.length) {
				enhanceAppointments = appointments.map((a) => {
					const whichProvider = providers?.find(
						(p) => p.providerId === String(a.providerId)
					);
					return {...whichProvider, ...a};
				});
			}
			setAppointments(enhanceAppointments);
			const totalPages = Math.ceil(appointments.length / itemsPerPage);
			setTotalPages(totalPages);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};
	
	useEffect(() => {
		fetchAppointments();
	}, []);
	
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};
	
	// Calculate the index range for the current page
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentAppointments = appointments
		.slice()
		.reverse()
		.slice(startIndex, endIndex);
	return (
		<>
			{loading ? (
				<Loading/>
			) : (
				<>
					<div className="flex justify-between my-label uppercase text-charcoal my-4">
						<div>{user?.firstName + " " + user?.lastName}</div>
						<div>{user?.phoneNumber || ""}</div>
					</div>
					<div className="my-text text-charcoal w-fit my-4">
						<p>{t("manageBookings")}</p>
					</div>
					
					{/* Pagination buttons */}
					{totalPages > 1 && (
						<div className="flex justify-between text-sienna-brown">
							<button
								className={` ${currentPage === 1 ? "text-gray-300" : ""}`}
								disabled={currentPage === 1}
								onClick={() => handlePageChange(currentPage - 1)}
							>
								<AiOutlineArrowLeft size={20}/>
							</button>
							<div>
								{Array.from({
									length: totalPages,
								}).map((_, index) => (
									<button
										key={index}
										onClick={() => handlePageChange(index + 1)}
										className={`text-sm mx-[1px] px-2 py-1 rounded-full  ${
											currentPage === index + 1
												? "bg-sienna-brown text-white"
												: "bg-gray-200 text-charcoal"
										}`}
									>
										{index + 1}
									</button>
								))}
							</div>
							
							<button
								className={` ${
									endIndex >= appointments.length ? "text-gray-300" : ""
								}`}
								disabled={endIndex >= appointments.length}
								onClick={() => handlePageChange(currentPage + 1)}
							>
								<AiOutlineArrowRight size={20}/>
							</button>
						</div>
					)}
					{currentAppointments.map((appointment) => (
						<Booking
							key={appointment.id}
							appointment={appointment}
							user={user}
							fetchAppointments={fetchAppointments}
						/>
					))}
					
					<CustomButton
						fullWidth={true}
						text={`${removeAccents(t("backToHome"))} >`}
						onClick={() => goToHome()}
					/>
				</>
			)}
		</>
	);
});

export default ManageBookings;
